import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { Channel, Genre } from '@app/shared/domain';
import { ChannelService, VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { EditChannelDialogComponent } from '..';

@Component({
  selector: 'app-external-channel-dialog',
  templateUrl: './external-channel-dialog.component.html',
  styleUrls: ['./external-channel-dialog.component.scss'],
})
export class ExternalChannelDialogComponent implements OnInit {
  public channel: Channel;
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelForm: FormGroup;
  public isLoading: boolean;
  public genres: Genre[];
  channelThumbnail: any;
  file: File;
  isVisible: boolean;

  channelImage: string;
  constructor(
    protected formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditChannelDialogComponent>,
    protected notifier: NotifierService,
    protected channelService: ChannelService,
    protected videoService: VideoService,
    @Inject(MAT_DIALOG_DATA) public data: ChannelExternalDialogModel
  ) {
    if (data && data.channel) {
      this.channel = data.channel;
      this.channelThumbnail = data.channel.image;
    }
  }

  ngOnInit() {
    this.getGenres();
    this.buildForm();
  }

  get f() {
    return this.channelForm.controls;
  }

  getGenres() {
    const useGenreLabels = true;
    this.videoService.getGenreOptions(useGenreLabels).subscribe(
      (res) => {
        this.genres = res.genres;
      },
      () => {
        this.notifier.notify('error', 'Genre options cannot be fetched');
      }
    );
  }

  buildForm() {
    this.isVisible = this.channel?.is_visible == 'True';
    this.channelForm = this.formBuilder.group({
      title: [this.channel?.title, [Validators.required, NoWhitespaceValidator()]],
      description: [this.channel?.description, [Validators.required, NoWhitespaceValidator()]],
      streamUrl: [this.channel?.stream_url, [Validators.required, NoWhitespaceValidator()]],
      genres: [this.channel?.genres],
      isVisible: [this.isVisible],
    });
  }

  protected prepareFormData(): FormData {
    const { title, description, streamUrl, genres, isVisible } = this.channelForm.value;

    // Commenting because titles don't need to be unique, but slugs do
    // Using a common helper forces 2 channels with the same title to share the same slug
    // const slug = CommonHelpers.createSlug(title);

    const fmData = new FormData();
    fmData.append('title', title);
    fmData.append('description', description);
    // fmData.append('slug', slug);
    fmData.append('stream_url', streamUrl);
    fmData.append('stream_hls_url', streamUrl); // (only when updating external channel - stream_url and stream_hls_url can be the same)
    fmData.append('stream_mode', 'EXTERNAL');
    genres.forEach((genre: string) => fmData.append('genres', genre));
    fmData.append('is_visible', isVisible);
    fmData.append('active', 'true');
    if (this.file && this.file.size) {
      fmData.append('image', this.file);
    }
    return fmData;
  }

  handleUpdate(): void {
    if (this.channelForm.invalid) {
      CommonHelpers.validateAllFormFields(this.channelForm);
      return;
    }
    this.isLoading = true;
    const fmData = this.prepareFormData();
    if (this.channel && this.channel.uuid) {
      this.channelService.updateChannel(fmData, this.channel.uuid).subscribe(
        (res) => {
          // FormData isn't able to send a blank list, so I need a special API call that uses plain JSON
          const { genres } = this.channelForm.value;
          if (!genres.length) this.channelService.updateChannel({ genres: [] }, this.channel.uuid).subscribe();
          this.dialogRef.close(true);
        },
        (error) => {
          this.notifier.notify('error', 'Something went wrong');
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      this.channelService.createChannel(fmData).subscribe(
        (res) => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.notifier.notify('error', error);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  handleUploadChannelThumbnail(files: FileList) {
    const file = files[0];
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.channelThumbnail = event.target.result;
    };
  }

  doToggleVisible(e: MatSlideToggleChange) {
    this.channelForm.get('isVisible').setValue(e.checked);
  }

  ngOnDestroy() {}
}

export class ChannelExternalDialogModel {
  constructor(public channel?: Channel) {}
}
